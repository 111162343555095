<template>
  <div class="inner-layout">

    <div class="page-title">1:1문의</div>

    <div class="board-container board-inquiry">
      <div class="inquiry-subject"><span class="color-1">Q. </span><span>{{ item.title }}</span></div>

      <div class="inquiry-box" v-html="String(item.content).nl2br()"></div>

      <div v-if="item.answerContent" class="inquiry-subject"><span class="color-1">A. </span><span>[RE] {{ item.title }}</span></div>

      <div v-if="item.answerContent" class="inquiry-box bgcolor-white3" v-html="String(item.answerContent).nl2br()"></div>

      <div class="mt-50 ta-center">
        <button @click="goList" class="mr-5 btn w-100 btn-outline color-6">목록</button>
        <template v-if="item.writerIdx == getId && !item.answerContent">
          <button @click="confirmRemovePopup" class="mr-5 btn w-100 btn-outline color-6">삭제</button>
          <button @click="edit" class="btn w-100 color-1">수정</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
export default {
  name: "inquiry",
  data() {
    return {
      idx: this.$route.params.idx,
      item: {},
    }
  },
  computed: {
    ...mapGetters({
      getId: 'session/getId',
    }),
  },
  created() {
    this.setItem();
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      if (!this.idx) await this.$router.push('/inquiry');
      const { result, data } = await this.$api.getInquiryDetail({ idx: this.idx })
      if (result === 'success') this.item = data
    },
    goList() {
      location.href = '/inquiry'
    },
    edit() {
      this.$router.push(`/inquiry/${this.item.idx}/modify`)
    },
    confirmRemovePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          const { result } = await this.$api.removeInquiry({ idx: this.item.idx })
          if (result === 'success') location.href = '/inquiry'
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>
